import { graphql } from 'gatsby'
import React, { FC } from 'react'

import { PageProps } from '../../types/types'
import PageTemplate from './components/PageTemplate'

interface ServiceTemplateProps extends PageProps {
  data: { markdownRemark }
}

const ServiceTemplate: FC<ServiceTemplateProps> = ({
  data: { markdownRemark },
  location,
}) => {
  const { title, description } = markdownRemark.frontmatter
  const htmlAst = markdownRemark?.htmlAst

  return (
    <PageTemplate
      description={description}
      htmlAst={htmlAst}
      isBlogPost={false}
      pathname={location.pathname}
      title={title}
    />
  )
}

export default ServiceTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      htmlAst
      frontmatter {
        title
        description
      }
    }
  }
`
